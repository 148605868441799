fieldset {
  border-radius: 5px;
}
input[type=text]:read-only {
  background-color: #e9ecef;
  opacity: 1;
}
input.ng-invalid.ng-touched {
  border: 1px solid #a70014;
}
select.ng-invalid.ng-touched {
  border: 1px solid #a70014;
}
textarea.ng-invalid.ng-touched {
  border: 1px solid #a70014;
}
ng-select.ng-invalid.ng-touched .ng-select-container {
  border: 1px solid #a70014;
}
input.ng-valid.ng-touched {
  border: 1px solid #198754;
}
select.ng-valid.ng-touched {
  border: 1px solid #198754;
}
textarea.ng-valid.ng-touched {
  border: 1px solid #198754;
}
ng-select.ng-valid.ng-touched .ng-select-container {
  border: 1px solid #198754;
}
.breadcrumb a {
  text-decoration: none;
  color: #0d6efd;
}
.link {
  color: #0d6efd;
}
a {
  cursor: pointer;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
#toTop {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  background: url('assets/image/to_up.png') no-repeat;
  cursor: pointer;
  z-index: 999999;
}
.breadcrumb a {
  text-decoration: none;
}
p, h5 {
  margin-bottom: 0;
}
h4, h3 {
  margin-bottom: 5px;
}
h2, h3 {
  margin-top: 5px;
}
fieldset {
  margin-top: 15px;
}
h2, h3, h4, h5 {
  line-height: 1.7rem;
  margin-bottom: 0;
}
legend {
  float: none;
  font-size: 1rem;
  padding: 0 5px;
  font-weight: bold;
  margin-bottom: -12px;
}
table {
  font-size: .9rem;
}
.table > :not(caption) > * > * {
  padding: .25rem;
}
.form-text {
  font-size: .75em;
}
.btn-block {
  flex: 1;
}
.btn-default {
  border: 1px solid #ced4da;
}
/* Login Unesp */
.headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: 'Roboto', sans-serif;
}
.centralizado {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 100%;
  padding-right: 25%;
  padding-left: 25%;
}
.cardLogin {
  border-top: 8px solid rgba(var(--bs-primary-rgb));
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  padding: 15px;
  margin: 15px;
}
/* Imprimir */
@media not print {
  .print-header, .print-signature {
    display: none;
  }
  .no-print {
    display: block;
  }
}
@media print {
  .container {
    max-width: unset;
  }
  .no-print, .navbar, .teste, #toTop {
    display: none;
  }
  .print {
    size: a4;
    display: block;
    margin: 0;
    padding: 0;
  }
  @page {
    margin: 25px;
  }
  header, footer, nav {
    display: none;
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  legend {
    margin: 0;
    padding: 0;
    margin-bottom: -10px;
  }
  .print-header::before {
    content: url('assets/image/logo.png');
    position: relative;
    top: 0px;
  }
  .col-sm-9, .col-sm-8, .col-sm-6, .col-sm-4, .col-sm-3 {
    width: 100%;
  }
  .input-group-text {
    padding: 0;
    font-weight: bold;
    border: 0;
  }
  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    padding: 0;
    padding-left: 10px;
    border: 0;
  }
}
/* Mobile */
@media only screen and (max-width: 1023px) {
  .input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select, .input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
  }
  #toTop {
    display: none;
    background-image: unset;
    z-index: -1;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    cursor: none;
  }
  .input-group {
    display: grid;
    margin-bottom: 10px;
  }
  .input-group-text {
    background-color: none;
    border-radius: 0;
  }
  .input-group > .form-control, .input-group > .form-select {
    width: unset;
    border-radius: 0;
  }
  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
  }
  .centralizado {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .no-mobile {
    display: none;
  }
  .btn-search {
    display: flex;
  }
}
.breadcrumb-item, .breadcrumb-item a {
  display: inline-block;
  min-height: 32px;
}
.breadcrumb {
  margin-bottom: .5rem;
}
.navbar, .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 58px;
}
.btn-sm {
  margin-top: .25rem !important;
}
.ng-select.custom {
  border: 0;
  min-height: 38px;
  border-radius: 4px;
}
.ng-select.custom .ng-select-container {
  min-height: 38px;
  border-radius: 4px;
  width: 100%;
}